import { render, staticRenderFns } from "./pieChart.component.vue?vue&type=template&id=416a4fe2&scoped=true&"
import script from "./pieChart.component.vue?vue&type=script&lang=ts&"
export * from "./pieChart.component.vue?vue&type=script&lang=ts&"
import style0 from "./pieChart.component.vue?vue&type=style&index=0&id=416a4fe2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416a4fe2",
  null
  
)

export default component.exports