































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import ReportsProvider from '@/providers/reports.provider';

import BarChartComponent from '@/shared/barChart.component.vue';
import PieChartComponent from '@/shared/pieChart.component.vue';

import {
  SubIlosCourseQuestionsReportData,
  CourseQuestionsReportData,
} from '@/types/reports.type';
import { APPIDS, TABLE_HEADER_CLASS } from '../../enums';

@Component({
  components: {
    BarChartComponent,
    PieChartComponent,
  },
})
export default class IlosCategoryReportComponent extends Vue {
  @Prop() readonly questionStatus!: [];

  headerClass = TABLE_HEADER_CLASS;

  courseId = this.$route.params.courseId;

  appId = APPIDS.COURSE_QUESTIONS_STASTICS;

  chartData: CourseQuestionsReportData[] = [];

  subIlos: SubIlosCourseQuestionsReportData[] = [];

  error = '';

  limitOption = [5, 10, 20, 50];

  labelMaxWidth = 90;

  labelBreak = 1500;

  totalQuestionsCount = 0;

  dataFields = {
    category: 'code',
    value: 'value',
  };

  labelBulletObject = {
    dy: -10,
  };

  loading = false;

  get questionsXAxisLabel() {
    return this.$t('SUB_ILOS');
  }

  get questionsYAxisLabel() {
    return this.$t('questionsCount');
  }

  mounted() {
    this.getIlosCategory();
  }

  get headers() {
    const headersArray = [
      {
        text: this.$t('tableHeaders.subIloName'),
        align: 'center',
        value: 'name',
        class: this.headerClass,
        sortable: false,
      },
      {
        text: this.$t('tableHeaders.subILOCode'),
        sortable: false,
        value: 'code',
        align: 'center',
        class: this.headerClass,
        with: 50,
      },
      {
        text: this.$t('questionsCount'),
        sortable: false,
        value: 'value',
        align: 'center',
        class: this.headerClass,
      },
    ];

    return headersArray;
  }

  get headerOptions() {
    return { sortByText: this.$t('labels.sortBy') };
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
      disablePagination: this.loading,
      'show-first-last-page': true,
    };
  }

  async getIlosCategory() {
    this.loading = true;
    try {
      const dataRes = await ReportsProvider.getcourseQuestionsStatisticsByIlo(
        this.appId,
        this.courseId,
        {
          scopeId: this.$store.state.scope.selectedScopeId,
          category: '3',
          questionStatus: this.questionStatus.join(','),
        },
      );
      this.chartData = [];
      this.subIlos = [];

      const formalizedObj = this.formalizeRes(
        dataRes.reportData.data.SubIloQty,
      );
      this.$emit('reportSelected', {
        courseName: dataRes.reportData.courseName,
        planName: dataRes.reportData.planName,
      });
      /* eslint-disable max-len */

      if (dataRes.reportData.data.TotalQtyIloheader) {
        dataRes.reportData.data.TotalQtyIloheader.forEach((key) => {
          this.chartData.push({
            name: key.headerIlo,
            value: key.questionNumber,
          });
        });
      }

      Object.keys(formalizedObj.subILOS).forEach((key) => {
        this.subIlos.push({
          name: formalizedObj.subILOS[key].name,
          ilos: formalizedObj.subILOS[key].sub,
        });
      });
      this.loading = false;
    } catch (err) {
      this.error = err;
      this.loading = false;
    }
  }

  formalizeRes(ilos) {
    return ilos.reduce(
      (acc, ilo) => {
        acc.total += ilo.questionNumber;
        this.totalQuestionsCount = acc.total;
        /* eslint-disable max-len */

        if (!acc.subILOS[ilo.itemId as string]) acc.subILOS[ilo.itemId as string] = { total: 0, sub: [], name: ilo.headerIlo };

        acc.subILOS[ilo.itemId as string].total = acc.subILOS[ilo.itemId as string].total + ilo.questionNumber;
        acc.subILOS[ilo.itemId as string].sub.push({
          code: ilo.Code,
          value: ilo.questionNumber,
          name: ilo.ilo,
          headerIlo: ilo.headerIlo,
        });
        return acc;
      },
      {
        total: 0,
        subILOS: {
          // '1.1.': { total: 0, sub: [], name: {} },
          // '1.2.': { total: 0, sub: [], name: {} },
          // '1.3.': { total: 0, sub: [], name: {} },
          // '1.4.': { total: 0, sub: [], name: {} },
        },
      },
    );
  }

  @Watch('questionStatus', { deep: true })
  onStatusChanged() {
    this.getIlosCategory();
  }
}
