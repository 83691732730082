










/* eslint-disable*/
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  useTheme,
  create,
  color,
  percent,
  Label,
  DropShadowFilter,
} from "@amcharts/amcharts4/core";
import { PieChart, PieSeries, Legend } from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

useTheme(am4themesAnimated);

@Component
export default class PieChartComponent extends Vue {
  chartData = [
    { sector: "Agriculture", count: 6.6 },
    { sector: "Mining and Quarrying", count: 0.6 },
    { sector: "Manufacturing", count: 23.2 },
    { sector: "Electricity and Water", count: 2.2 },
    { sector: "Construction", count: 4.5 },
    { sector: "Trade (Wholesale, Retail, Motor)", count: 14.6 },
    { sector: "Transport and Communication", count: 9.3 },
    { sector: "Finance, real estate and business services", count: 22.5 },
  ];

  @Prop() legend!: boolean;

  @Prop() category!: string;

  @Prop() value!: string;

  @Prop() data!: [];

  @Prop() labelBreak!: number;
  @Prop() legendPosition!: string;

  @Watch("lang", { deep: true })
  onPropertyChanged() {
    this.drawChart();
  }

  @Watch("legendPosition", { deep: true })
  onlegendPositionChanged() {
    this.drawChart();
  }

  servertranslate = this.$options.filters!.servertranslate;

  $refs!: {
    chartdiv: HTMLElement;
  };

  private chart!: PieChart;

  get lang() {
    return this.$store.state.lang.lang;
  }

  mounted() {
    this.drawChart();
  }

  drawChart() {
    const chart = create(this.$refs.chartdiv, PieChart);

    chart.data = this.data;

    // Add and configure Series
    const pieSeries = chart.series.push(new PieSeries());
    // Let's cut a hole in our Pie chart the size of 50% the radius
    chart.innerRadius = percent(50);
    pieSeries.dataFields.value = this.value;
    pieSeries.dataFields.category = this.category;
    pieSeries.tooltip!.label.adapter.add(
      "text",
      (text, target: any) =>
        `${this.servertranslate(
          target.dataItem.category
        )}: {value.percent.formatNumber('#.0')}% ({value})`
    );
    // pieSeries.tooltip!.label.html =
    //   "{category} : {value.percent.formatNumber('#.#')}% ({value}) ";

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = color("#FFFFFF");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.showOnInit = true;
    pieSeries.slices.template.hiddenState.properties.shiftRadius = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "";
    pieSeries.labels.template.fontWeight = "bold";
    pieSeries.labels.template.bent = false;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(5, 5, 5, 5);
    pieSeries.ticks.template.disabled = false;

    const shadow = pieSeries.slices.template.filters.push(
      new DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    const hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    const hoverShadow = hoverState!.filters.push(new DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    this.createLegend(chart);

    // Add label
    this.showTotal(chart);
    chart.rtl = this.lang === "ar";
    this.chart = chart;
  }

  createLegend(chart) {
    /* eslint no-param-reassign: ["error", { "props": false }] */
    chart.legend = new Legend();
    if (this.legendPosition) {
      chart.legend.position = this.legendPosition;
    } else {
      chart.legend.align  = "right";
    }
    chart.legend.position = 'right';
    chart.legend.marginTop = "20";
    chart.legend.labels.template.truncate = false;
    chart.legend.labels.template.wrap = true;
    chart.legend.labels.template.adapter.add(
      "text",
      (text, target) => `${this.servertranslate(target.dataItem.category)}`
    );
    chart.legend.valueLabels.template.text =
      "{value.percent.formatNumber('#.#')}% ({value})";
    chart.legend.itemContainers.template.reverseOrder = this.lang === "ar";
    // chart.legend.reverseOrder = this.lang === "ar";
    chart.legend.labels.template.fontSize = 12;
    chart.legend.valueLabels.template.fontSize = 12;
    chart.legend.valueLabels.template.fontWeight = "bold";
    chart.legend.scrollable = true;
  }

  showTotal(chart) {
    const label = chart.seriesContainer.createChild(Label);
    label.text = this.data.reduce((acc, item) =>
      ( Math.round(acc + item[this.value]) * 100 ) / 100, 0);
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 20;
    chart.legend.itemContainers.template.events.on("hit", (ev) => {
      const legendItemContainers = chart.legend.itemContainers.values;
      label.text = legendItemContainers.reduce((acc, item) => {
        // Sum all the categories that are not active (not removed from chart)
        // the isActive flag for the target item should be toggled
        const currentItemCategory = item.dataItem.dataContext.category;
        const targetItemCategory = ev.target.dataItem.dataContext.category;
        const isActive =
          currentItemCategory === targetItemCategory
            ? !item.isActive
            : item.isActive;
        if (!isActive) {
          return (
            Math.round((acc + item.dataItem.dataContext.value) * 100) / 100
          );
        }
        return Math.round(acc * 100) / 100;
      }, 0);
    });
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
