







































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import ReportsProvider from '@/providers/reports.provider';

import BarChartComponent from '@/shared/barChart.component.vue';
import { CourseQuestionsReportData } from '@/types/reports.type';
import { APPIDS, TABLE_HEADER_CLASS } from '../../enums';

@Component({
  components: {
    BarChartComponent,
  },
})
export default class SubjectCategoryReportComponent extends Vue {
  @Prop() readonly questionStatus!: [];

  courseId = this.$route.params.courseId;

  appId = APPIDS.COURSE_QUESTIONS_STASTICS;

  chartData: CourseQuestionsReportData[] = [];

  error = '';

  limitOption = [5, 10, 20, 50];

  labelMaxWidth = 90;

  labelBreak = 1500;

  headerClass = TABLE_HEADER_CLASS;

  dataFields = {
    category: 'subjectName',
    value: 'questionNumber',
  };

  labelBulletObject = {
    dy: -10,
  };

  loading = false;

  totalQuestionsCount = 0;

  get questionsXAxisLabel() {
    return this.$t('labels.subject');
  }

  get questionsYAxisLabel() {
    return this.$t('questionsCount');
  }

  get headers() {
    const headersArray = [
      {
        text: this.$t('labels.subject'),
        align: 'center',
        value: 'subjectName',
        class: this.headerClass,
        sortable: false,
      },
      {
        text: this.$t('questionsCount'),
        sortable: false,
        value: 'questionNumber',
        align: 'center',
        class: this.headerClass,
      },

    ];

    return headersArray;
  }

  get headerOptions() {
    return { sortByText: this.$t('labels.sortBy') };
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
      disablePagination: this.loading,
      'show-first-last-page': true,
    };
  }

  mounted() {
    this.getSubjectCategory();
  }

  async getSubjectCategory() {
    this.loading = true;

    try {
      const dataRes = await ReportsProvider.getcourseQuestionsStatistics(
        this.appId,
        this.courseId,
        {
          scopeId: this.$store.state.scope.selectedScopeId,
          category: '2',
          questionStatus: this.questionStatus.join(','),
        },
      );
      this.$emit('reportSelected', {
        courseName: dataRes.reportData.courseName,
        planName: dataRes.reportData.planName,
      });
      this.chartData = dataRes.reportData.data;

      this.totalQuestionsCount = this.totalQuestions(dataRes.reportData.data);
      this.loading = false;
    } catch (err) {
      this.error = err;
      this.loading = false;
    }
  }

  totalQuestions(subjects) {
    return subjects.reduce((acc, subject) => acc + subject.questionNumber, 0);
  }

  @Watch('questionStatus', { deep: true })
  onStatusChanged() {
    this.getSubjectCategory();
  }
}
