







































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import ReportsProvider from '@/providers/reports.provider';

import BarChartComponent from '@/shared/barChart.component.vue';

import { COURSEQuestionsReportTable } from '@/types/reports.type';
import { APPIDS } from '../../enums';

@Component({
  components: {
    BarChartComponent,
  },
})
export default class DifficultyCategoryReportComponent extends Vue {
  @Prop() readonly questionStatus!: [];

  courseId = this.$route.params.courseId;

  appId = APPIDS.COURSE_QUESTIONS_STASTICS;

  dataRes: COURSEQuestionsReportTable = {
    reportData: {
      data: [],
    },
  };

  error = '';

  labelMaxWidth = 90;

  loading = false;

  labelBreak = 1500;

  dataFields = {
    category: 'levelName',
    value: 'questionNumber',
  };

  labelBulletObject = {
    dy: -10,
  };

  totalQuestionsCount = 0;

  $options!: {
    filters: {
      servertranslate;
    };
  };

  servertranslate = this.$options.filters.servertranslate;

  get questionsXAxisLabel() {
    return this.$t('difficultyDegree');
  }

  get questionsYAxisLabel() {
    return this.$t('questionsCount');
  }

  mounted() {
    this.getDifficultyCategory();
  }

  async getDifficultyCategory() {
    this.loading = true;
    try {
      this.dataRes = await ReportsProvider.getcourseQuestionsStatistics(
        this.appId,
        this.courseId,
        {
          scopeId: this.$store.state.scope.selectedScopeId,
          category: '1',
          questionStatus: this.questionStatus.join(','),
        },
      );

      this.totalQuestionsCount = this.totalQuestions(
        this.dataRes.reportData.data,
      );

      this.$emit('reportSelected', {
        courseName: this.dataRes.reportData.courseName,
        planName: this.dataRes.reportData.planName,
      });
      this.loading = false;
    } catch (err) {
      this.error = err;
      this.loading = false;
    }
  }

  totalQuestions(difficulties) {
    return difficulties.reduce(
      (acc, difficultie) => acc + difficultie.questionNumber,
      0,
    );
  }

  get chartData() {
    return this.dataRes.reportData.data.map((item) => ({
      ...item,
      levelName: this.servertranslate(item.levelName),
    }));
  }

  @Watch('questionStatus', { deep: true })
  onStatusChanged() {
    this.getDifficultyCategory();
  }
}
