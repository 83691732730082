























































































import { Component, Vue } from 'vue-property-decorator';
import SelectedCourse from '@/components/SelectedCourse.component.vue';
import ReportsProvider from '@/providers/reports.provider';
import SubjectCategoryReportComponent from './subjectCategoryReport.component.vue';
import DifficultyCategoryReportComponent from './difficultyCategoryReport.component.vue';
import IlosCategoryReportComponent from './ilosCategoryReport.component.vue';
import { APPIDS, COURSES_ROUTE_NAME, COURSE_QUESTIONS_STASTICS } from '../../enums';
import { BasicFilterInput } from '../../types/questions.type';

@Component({
  components: {
    SubjectCategoryReportComponent,
    DifficultyCategoryReportComponent,
    IlosCategoryReportComponent,
    SelectedCourse,
  },
})
export default class CourseQuestionsStatisticsComponent extends Vue {
  courseId = this.$route.params.courseId;

  appId = APPIDS.COURSE_QUESTIONS_STASTICS;

  error = '';

  courseName = '';

  planName = '';

  $options!: {
    filters: {
      servertranslate;
    };
  };

  loading = false;

  servertranslate = this.$options.filters.servertranslate;

  selectedReportType = '1';

  questionStatus = [];

  questionStatusRes: BasicFilterInput[] = [];

  mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
    this.getQuestionStatus();
  }

  get reportTypes() {
    return [
      {
        name: this.$t('labels.difficultyLevel'),
        id: '1',
      },
      {
        name: this.$t('subjects'),
        id: '2',
      },
      {
        name: this.$t('ilos'),
        id: '3',
      },
    ];
  }

  setCourseName({ courseName, planName }) {
    this.courseName = courseName;
    this.planName = planName;
  }

  viewCourse() {
    this.$router.push({
      name: COURSES_ROUTE_NAME.VIEW,
      params: {
        courseId: this.courseId,
      },
    });
  }

  async getQuestionStatus() {
    this.loading = true;
    try {
      this.questionStatusRes = await ReportsProvider.getQuestionStatus(
        this.appId,
        this.courseId,
        {},
      );
      this.loading = false;
    } catch (err) {
      this.error = err;
      this.loading = false;
    }
  }

  get questionStatusList() {
    return this.questionStatusRes.map((item) => ({
      ...item,
      name: this.servertranslate(item.name),
    }));
  }

  onBack() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: COURSE_QUESTIONS_STASTICS.COURSES_LIST, query: courseFilters });
  }
}
