import { BasicFilterInput } from '@/types/questions.type';
import {
  COURSEQuestionsReportILO, COURSEQuestionsReportTable, QuestionsStatusStatsReportResponse, BasicCreator, BasicCourse, ElectronicExamsReportResponse,
} from '@/types/reports.type';
import provider from './main.provider';

class ReportsProvider {
  async getQuestionsStatusStatsReport(appId: string, query: object) {
    const result: QuestionsStatusStatsReportResponse = await provider.get(
      `/api/reports/${appId}/getQuestionsStatusStatsReport`, {
        params: query,
      },
    );
    return result;
  }

  async getcourseQuestionsStatistics(appId: string, courseId: string, query: object) {
    const result: COURSEQuestionsReportTable = await provider.get(
      `/api/reports/${appId}/${courseId}/getCourseQuestionsStatistic`, {
        params: query,
      },
    );
    return result;
  }

  async getcourseQuestionsStatisticsByIlo(appId: string, courseId: string, query: object) {
    const result: COURSEQuestionsReportILO = await provider.get(
      `/api/reports/${appId}/${courseId}/getCourseQuestionsStatistic`, {
        params: query,
      },
    );
    return result;
  }

  async getQuestionStatus(appId: string, courseId: string, query: object) {
    const result: BasicFilterInput[] = await provider.get(
      `/api/questions/${appId}/${courseId}/getQuestionStatus`,
      { params: query },

    );
    return result;
  }

  async getExamsCreatorsReportFilters(appId: string, query: object) {
    const result: BasicCreator[] = await provider.get(
      `/api/reports/${appId}/getExamsCreatorsReportFilters`, { params: query },

    );
    return result;
  }

  async getCreatorsCoursesReportFilters(appId: string, query: object) {
    const result: BasicCourse[] = await provider.get(
      `/api/reports/${appId}/getCreatorsCoursesReportFilters`, { params: query },

    );
    return result;
  }

  async getExamsCreatorsReport(appId: string, query: object): Promise<ElectronicExamsReportResponse> {
    const result: ElectronicExamsReportResponse = await provider.get(`/api/reports/${appId}/getExamsCreatorsReport`, { params: query });
    return result;
  }
}

export default new ReportsProvider();
